<template>
  <div class="news-full__items">
    <news-aside-card v-for="item in items" :key="item.id" :item="item" />
  </div>
</template>

<script>
export default {
  name: "NewsAsideList",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  components: {
    NewsAsideCard: () => import("@/components/news/NewsAsideCard.vue"),
  },
};
</script>

<style lang="scss" scoped></style>
